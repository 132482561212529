'use strict'

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { MotionPathPlugin } from "gsap/MotionPathPlugin";
gsap.registerPlugin(ScrollTrigger, MotionPathPlugin);



// Menu fade animation
const nav = document.querySelector('.navigation');

const handleHover = function (e) {
    if (e.target.classList.contains('navigation__link')) {
        const link = e.target;
        // NodeList with all children of .nav with class 'nav__link'
        const siblings = link.closest('.navigation').querySelectorAll('.navigation__link');

        siblings.forEach(el => {
            // This makes reference to 'e', which is the parameter passed into the function, which is the opacity
            // value
            if (el !== link) el.style.opacity = this;
        });
    }
}

nav.addEventListener('mouseover', handleHover.bind(0.5));
nav.addEventListener('mouseout', handleHover.bind(1));


// Sticky navigation
const stickyActivator = document.querySelector('.sticky-activator');
const logo = document.querySelector('.navigation__img');
const responsiveIcon = document.querySelector('.navigation__icon');
const jan = document.getElementById('jan');

const stickyNav = function (entries) {
    const [entry] = entries;
    console.log(entry);
    if (!entry.isIntersecting) {
        nav.classList.add('sticky');
        // responsiveIcon.src = './responsive-icon-black.2dd43272.svg';
        jan.style.color = "#000";
    } else {
        nav.classList.remove('sticky');
        // responsiveIcon.src = './responsive-icon.97753ffd.svg';
        jan.style.color = "#000";
        // logo.src = 'media/logo.svg';
    }
}

const headerObserver = new IntersectionObserver(stickyNav, {
    root: null,
    threshold: 0.10
});

headerObserver.observe(stickyActivator);



///////////////////////////////////////
// Modal window

const overlay = document.querySelector('.overlay');
const btnCloseModal = document.querySelectorAll('.btn--close-modal');
const btnsOpenModal = document.querySelectorAll('.btn--show-modal');
var currModal;

const displayModal = function(dataBindValue) {
    currModal = document.querySelector(`[data-modal-name = ${dataBindValue}]`);
    console.log('displayModel currModal === ', currModal);
    currModal.classList.remove('hidden');
    overlay.classList.remove('hidden');
}


const openModal = function (e) {
    console.log('e.target === ', e.target);
    displayModal(e.target.getAttribute('data-modal-bind'));
};

const closeModal = function () {
    console.log('closeModal currModal === ', currModal);
    currModal.classList.add('hidden');
    overlay.classList.add('hidden');
};

btnsOpenModal.forEach(btn => btn.addEventListener('click', openModal));
btnCloseModal.forEach(btn => btn.addEventListener('click', closeModal));

overlay.addEventListener('click', closeModal);

document.addEventListener('keydown', function (e) {
    if (e.key === 'Escape' && !modal.classList.contains('hidden')) {
        closeModal();
    }
});



// Ball homepage effect

// const ball = document.getElementById('ball');
// console.log('Ball === ', ball);

// // This variables will store where the mouse is
// let mouseX = 0;
// let mouseY = 0;

// // This variables will store where the ball is
// let ballX = 0;
// let ballY = 0;

// // The higher this number is the quicker the ball will move towards user mouse
// let speed = 0.04;

// function animate() {
//     // The difference between where the mouse is and where the ball is
//     let distX = mouseX - ballX
//     let distY = mouseY - ballY

//     ballX = ballX + (distX * speed);
//     ballY = ballY + (distY * speed);

//     ball.style.left = ballX + "px"
//     ball.style.top = ballY + "px"

//     requestAnimationFrame(animate)
// }

// animate();

// document.addEventListener('mousemove', function (event) {
//     mouseX = event.pageX
//     mouseY = event.pageY
// });



